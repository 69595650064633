.sidebar {
  background-color: var(--tmc);
  transition: var(--fast_t);
  z-index: var(--l9);
  width: 20%;
  height: 100%;
  min-height: 100vh;
  position: sticky;
  top: 0;
}

.sidebar-t {
  width: 100px;
}

.sidebar-head {
  background-color: var(--tmc);
  z-index: var(--l8);
  padding: 10px 48px 10px 10px;
  position: sticky;
  top: 0;
}

.comp-name {
  color: #f5f5f5;
  font-family: var(--font);
  font-size: var(--md_f);
  font-style: var(--normal);
  font-weight: var(--fw8);
}

.logo {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 0 15px 0 10px;
}

.sidebar-toggle {
  background-color: var(--white);
  z-index: var(--l9);
  filter: var(--shadow_2);
  border: 1px solid var(--tmc);
  transition: var(--fast_t);
  border-radius: 50%;
  padding: 7px;
  position: absolute;
  right: -16px;
}

.sidebar-toggle-180 {
  transform: rotate(180deg);
}

.st-btn {
  width: 18px;
  height: 18px;
}

.sidebar-links {
  padding: 25px;
  overflow-y: auto;
}

.side-l {
  margin: 5px 0;
}

.side-link {
  border-radius: var(--br_r);
  padding: 12px 13px;
  transition: all .3s;
}

.side-link:hover {
  background-color: var(--tmc_bg);
}

.side-active {
  background-color: var(--tmc_bg);
  height: auto;
}

.side-link-icon {
  color: var(--side_icon);
  width: 23px;
  height: 23px;
  margin-right: 7px;
}

.side-link-text {
  color: var(--side_text);
  font-family: var(--font);
  font-style: normal;
  font-size: var(--side_t);
  font-weight: var(--fw8);
}

.side-arrow {
  transition: all .3s;
  transform: rotate(-90deg);
}

.side-arrow-active {
  transition: all .3s;
  transform: rotate(90deg);
}

.side-option-text {
  color: var(--side_text);
  font-family: var(--font);
  font-size: var(--side_tm);
  margin: 8px 0 8px 28px;
}

.side-options {
  display: none;
}

.side-options-active {
  display: block;
}

/*# sourceMappingURL=index.2d372bbb.css.map */
