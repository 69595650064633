.sidebar {
    position: sticky;
    top: 0;
    background-color: var(--tmc);
    min-height: 100vh;
    height: 100%;
    width: 20%;
    transition: var(--fast_t);
    z-index: var(--l9);
}
.sidebar-t{
    width: 100px;
}

.sidebar-head{
    padding: 10px 48px 10px 10px;
    position: sticky;
    top: 0;
    background-color: var(--tmc);
    z-index: var(--l8);
}
.comp-name{
    color: #F5F5F5;
    font-family: var(--font);
    font-size: var(--md_f);
    font-style: var(--normal);
    font-weight: var(--fw8);
}
.logo{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 15px 0 10px;
}
.sidebar-toggle{
    padding: 7px;
    background-color: var(--white);
    border-radius: 50%;
    position: absolute;
    right: -16px;
    z-index: var(--l9);
    filter: var(--shadow_2);
    border: 1px solid var(--tmc);
    transition: var(--fast_t);
}
.sidebar-toggle-180{
    transform: rotate(180deg);
}
.st-btn{
    width: 18px;
    height: 18px;
}


/* navigation part */
.sidebar-links{
    padding: 25px;
    /* overflow: hidden; */
    /* height: 84%; */
    overflow-y: auto;
}
.side-l{
    margin: 5px 0;
}
.side-link{
    padding: 12px 13px;
    border-radius: var(--br_r);
    transition: 0.3s;
}
.side-link:hover{
    background-color: var(--tmc_bg);
}
.side-active{
    background-color: var(--tmc_bg);
    height: auto;
}
.side-link-icon{
    width: 23px;
    height: 23px;
    color: var(--side_icon);
    margin-right: 7px;
}
.side-link-text{
    color: var(--side_text);
    font-family: var(--font);
    font-style: normal;
    font-size: var(--side_t);
    font-weight: var(--fw8);
}
.side-arrow{
    transition: 0.3s;
    transform: rotate(-90deg);
}
.side-arrow-active{
    transition: 0.3s;
    transform: rotate(90deg);
}
.side-option-text{
    color: var(--side_text);
    font-family: var(--font);
    font-size: var(--side_tm);
    margin: 8px 0 8px 28px;
}
.side-options{
    display: none;
}
.side-options-active{
    display: block;
}
/* navigation part ends */